import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Pagination, Autoplay } from "swiper";

const HeroLanding = () => {
    return (
        <>
            <Swiper
                pagination={{ clickable: true }}
                autoplay={{ delay: 5000, disableOnInteraction: false }}
                modules={[Pagination, Autoplay]}
                className="h-[40vh] md:h-[60vh] lg:h-[80vh]">

                <SwiperSlide>
                    <div className="w-full h-full relative aspect-[16/9]">
                        <img
                            className="h-full w-full object-cover"
                            src="/images/hero/banner.png" alt="abidin"
                        />
                        
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="w-full h-full relative">
                        <img
                            className="h-full w-full object-cover"
                            src="/images/hero/1.jpg" alt="abidin"
                        />  
                        
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="w-full h-full relative">
                        <img
                            className="h-full w-full object-cover"
                            src="/images/hero/2.jpg" alt="abidin"
                        />  
                        
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="w-full h-full relative">
                        <img
                            className="h-full w-full object-cover"
                            src="/images/hero/3.jpg" alt="abidin"
                        />  
                        
                    </div>
                </SwiperSlide>
            </Swiper>
        </>
    )
}

export default HeroLanding