import React from 'react'
import ReactImageFallback from 'react-image-fallback'

const WhyChoose = () => {
  return (
    <div className='mt-24 relative bg-[#2E7462] md:bg-transparent'>
      <ReactImageFallback
        className='w-full min-h-full hidden md:block'
        fallbackImage='/images/bg-whychoose-fb.png'
        initialImage='/images/bg-whychoose-fb.png'
        src='/images/bg-whychoose.png'
        alt='why-madani'
      />
      <div className='md:absolute items-center grid lg:grid-cols-12 left-0 right-0 bottom-0 top-0 text-white'>
        <div className='lg:col-span-2' />
        <div className='lg:col-span-8 px-4 lg:px-6'>
          <div className='mb-[34px] text-center lg:text-left'>
            <h2 className='text-[36px] mb-[16px] font-semibold'>
              Why Choose Us?
            </h2>
            <p className='w-full lg:w-2/3 leading-[30px] mx-auto lg:mx-0'>
              Find out why we're the best choice for your needs, with our
              top-quality products, exceptional service, and unrivaled
              expertise.
            </p>
          </div>
          <div className='grid md:grid-cols-2 gap-12'>
            <div className='mb-6'>
              <h2 className='text-[22px] mb-[22px] font-semibold'>
                Powerful And Accurate Digital Signage Solution
              </h2>
              <p className='leading-[30px]'>
                Equipped with the high quality hardware in the market, our
                digital signage able to run any content perfectly. Either a
                single or multiple display placement, our solution has been
                adopted by various industries to catch their audiences in the
                most captivating way possible.
              </p>
            </div>
            <div className='mb-6'>
              <h2 className='text-[20px] mb-[22px] font-semibold'>
                Impress And Inspire
              </h2>
              <p className='text-[16px] leading-[30px]'>
                Broaden the communication term to the next level. Interactive
                display was meant to deliver message to your audiences in a
                better way. Use it as you like as a map directory, or
                interactive presentation, or smart room whiteboard, or loyalty
                survey, you name it. Impress them, so you can inspire them.
              </p>
            </div>
            <div className='mb-6'>
              <h2 className='text-[20px] mb-[22px] font-semibold'>
                One Workstation To Guide Them All
              </h2>
              <p className='text-[16px] leading-[30px]'>
                Assemble multiple displays and solutions in a room where you can
                orchestrate your whole plans and strategies in track. Our
                control room/command center solution frequently used by
                government institutions or enterprises.
              </p>
            </div>
            <div className='mb-6'>
              <h2 className='text-[20px] mb-[22px] font-semibold'>
                Collaboration At Ease
              </h2>
              <p className='text-[16px] leading-[30px]'>
                Increase your team productivity with a set of advanced display
                technologies in your meeting room. No wire, no hassle, we
                connect anyone you want to your meeting room. We bring future.
              </p>
            </div>
          </div>
        </div>
        <div className='lg:col-span-2' />
      </div>
    </div>
  )
}

export default WhyChoose
