import React from 'react'
import { useNavigate } from 'react-router-dom'

const SubscribeFlotaing = () => {
  const navigate = useNavigate()
  return (
    <div className='relative'>
      <div className='absolute hidden md:flex left-0 right-0 -top-32 -bottom-24 px-4 lg-px-0'>
        <div className='bg-subs max-w-5xl w-full mx-auto h-48 rounded-xl flex items-center justify-between px-12'>
          <h2 className='text-[#2E7462] text-[36px] font-semibold w-1/2'>
            Order Now To Get Special Features!
          </h2>
          <button
            onClick={() => navigate('/services')}
            className='bg-[#23BB86] py-4 px-12 rounded-lg text-white'
          >
            Order Now
          </button>
        </div>
      </div>
    </div>
  )
}

export default SubscribeFlotaing
