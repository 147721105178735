import React, { useState } from "react";
import { FaWhatsapp } from "react-icons/fa";

const FloatingWhatsApp = () => {
    const phoneNumber = "6289507085550"; 
    const message = "Halo, saya ingin bertanya tentang layanan Angkasa.";
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

    const [isHovered, setIsHovered] = useState(false);

    return (
        <div className="fixed bottom-4 right-4 flex items-center space-x-2 z-50">
            {}
            <div
                className={`bg-green-500 text-white text-sm px-3 py-1 rounded-md shadow-md transition-all duration-300 whitespace-nowrap ${
                    isHovered ? "opacity-100 translate-x-0" : "opacity-0 -translate-x-3"
                }`}
            >
                Contact Us
            </div>

            {}
            <a
                href={whatsappURL}
                target="_blank"
                rel="noopener noreferrer"
                className="bg-green-500 text-white p-3 rounded-full shadow-lg hover:bg-green-600 transition duration-300 flex items-center"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <FaWhatsapp size={24} />
            </a>
        </div>
    );
};

export default FloatingWhatsApp;
